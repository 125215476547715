<template>
  <div class="information">
    <div class="information-basic">
      <p class="title">基础资料 </p>
      <el-row class="content" :gutter="24">
        <el-col :span="12">
          <el-form label-width="80px">
            <el-form-item label="企业编号">
              <span class="uuid">{{info.org.uuid}}</span>
            </el-form-item>
            <el-form-item label="企业全称">
              <el-input
                :disabled="!isEdit"
                size="small"
                v-model="info.org.name"
              />
            </el-form-item>
            <el-form-item label="企业简称">
              <el-input
                size="small"
                :disabled="!isEdit"
                v-model="info.org.simpleName"
              />
            </el-form-item>
            <el-form-item label="企业愿景">
              <el-input
                size="small"
                :disabled="!isEdit"
                v-model="info.org.slogan"
              />
            </el-form-item>

            <el-form-item label="企业logo">
              <div class="content-logo">
                <div class="img">
                  <img :src="info.org.logo">
                </div>
                <span>请上传分辨率为64*48、背景透明的logo</span>
              </div>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="10">
          <el-form label-width="120px">
            <el-form-item label="预警开关">
              <el-switch :disabled="!isEdit" v-model="info.alarm.alarmSw"/>
            </el-form-item>
            <el-form-item label="预警值">
              <el-input-number
                size="small"
                :disabled="!isEdit"
                v-model.number="info.alarm.alarmMoney"
                :min="0" :step="0.1"
              />
            </el-form-item>
            <el-form-item label="预警电话">
              <el-input
                size="small"
                :disabled="!isEdit"
                placeholder="默认通知企业负责人"
                v-model="info.alarm.alarmPhone"
              />
            </el-form-item>


            <el-form-item label="收件人/联系方式">
              <el-col :span="9" style="padding-left: 0">
                <el-input
                  size="small"
                  :disabled="!isEdit"
                  v-model="info.address.contactName"
                />
              </el-col>

              <el-col :span="15" style="padding-right: 0">
                <el-input
                  size="small"
                  :disabled="!isEdit"
                  v-model="info.address.contactPhone"
                />
              </el-col>
            </el-form-item>

            <el-form-item label="发票收件地址">
              <el-input
                type="textarea"
                :rows="3"
                :disabled="!isEdit"
                v-model="info.address.address"
              />
            </el-form-item>

            <el-form-item class="submit-title" v-if="isEdit">
              <el-button type="primary" size="small" @click="handleSaveInfo">
                保存信息
              </el-button>
            </el-form-item>


          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="information-module">
      <p class="title">企业模块 </p>
      <el-tabs
        :default-active-key="key"
        :tab-position="mode">
        <el-tab-pane v-for="(module, idx) in modules" :key="idx" :label="module.name">
          <ul class="modules">
            <li
              v-for="(item)  in module.modules"
              :key="item.uuid"
            >
              <i class="iconfont" :class="item.iconFront" :style="{fontSize: '20px',}"/>
              <p><span class="dot"></span>{{item.name}}</p>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </div>


  </div>
</template>

<script>
  import {checkPhone} from "@/utils/check"

  export default {
    name: 'org-basic-information',
    components: {},
    mounted: function () {
      this.$api('account.org.infoUnion').then(data => {
        data.alarm.alarmMoney = data.alarm.alarmMoney / 100
        data.alarm.alarmSw = data.alarm.alarmSw === 1
        this.info = data
      })

      this.isEdit = this.$btnCheck('org_information_edit')

      if (this.isEdit) {
        this.$api('org.role.orgModules').then(data => {
          this.modules = data.module || []
          for (let i = 0; i < this.modules.length; i++) {
            let module = this.modules[i]
            if (module.modules !== undefined && module.modules.length > 0) {
              this.key = module.uuid
              break
            }
          }
        })
      }


    },
    data: function () {
      return {
        mode: 'left',
        key: '',
        isEdit: false,
        info: {
          org: {},
          address: {},
          alarm: {},
        },
        modules: [],
      }
    },
    methods: {
      handleSaveInfo() {
        let data = {
          baseInfo: Object.assign({}, this.info.org),
          alarm: Object.assign({}, this.info.alarm),
          address: Object.assign({}, this.info.address),
        }

        let ok = checkPhone(data.alarm.alarmPhone)
        if (!ok) {
          this.$message.warn('请输入正确的手机号码')
          return
        }

        let reg = /.+?(省|市|自治区|自治州|县|区)/g;
        let res = data.address.address.match(reg)
        if (res && res.length > 0) {
          data.address.province = res[0]
          data.address.city = res[1]
        }

        data.alarm.alarmMoney = data.alarm.alarmMoney * 100
        data.alarm.alarmSw = data.alarm.alarmSw ? 1 : 0

        this.$api('account.org.modify', {}, data).then(() => {
          this.$message.success('更新成功')
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .information {
    padding: $container-padding;

    .title {
      font-weight: 500;
    }

    &-basic {
      .ant-form-item {
        margin-bottom: $small-space;

        &-label > label {
          @include font-little();
          color: $remark-text-color;

        }
      }

      ::v-deep .el-form-item {
        margin-bottom: $small-space;

        .el-form-item__label {
          font-size: 13px;
        }
      }

      .content {
        margin: $small-space 0;

        .uuid {
          color: $remark-text-color;
        }

        &-logo {
          border: 1px dashed $gray-5;
          text-align: center;
          padding: 4px;
          cursor: pointer;


          .img {
            width: 64px;
            height: 48px;
            margin: auto;
            margin-top: 16px;
            padding: 8px;
            display: block;

            img {
              width: 64px;
              height: 48px;
            }
          }

          span {
            @include font-little();
            color: $gray-7;
          }
        }

        .submit-title {
          .ant-form-item-label {
            label:after {
              content: '';
            }
          }

        }
      }
    }

    &-module {
      padding-top: $middle-space;
      border-top: 1px solid $container-border-color;

      .modules {
        margin: $small-space;

        li {
          width: 80px;
          height: 80px;
          float: left;
          padding-top: 20px;
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 4px;
          padding-bottom: 20px;
          text-align: center;
          position: relative;
          cursor: pointer;
          -webkit-transition: all .2s linear;
          transition: all .2s linear;

        }

        li:hover {
          transform: scale(1.2);
          background-color: #f8f8f8;
        }
      }

      .modules:after {
        clear: both;
        display: block;
        content: " ";
      }

    }
  }
</style>
